.content {
    position: relative;
    padding: 225px 0;
}
.title {
    font-weight: 700;
    font-size: 48px;
    line-height: 63px;
    max-width: 450px;
    text-align: center;
    margin-inline: auto;
    color: var(--main-black);
}
.title .evidence {
    color: var(--main-color);
}

.firstIcon {
    position: absolute;
    top: 120px;
    left: 8%;
}
.secondIcon {
    position: absolute;
    top: 330px;
    left: 0;
}
.thirdIcon {
    position: absolute;
    bottom: 20px;
    left: 16%;
}
.fourthIcon {
    position: absolute;
    top: 150px;
    right: 15%;
}
.fiveIcon {
    position: absolute;
    top: 300px;
    right: 0;
}
.sixIcon {
    position: absolute;
    bottom: 10px;
    right: 15%;
}
.textShape {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 47%;
}

@media screen and (max-width: 767px) {
    .secondIcon,
    .fiveIcon {
        display: none;
    }
    .thirdIcon {
        left: 2%;
    }
    .sixIcon {
        bottom: 100px;
        right: 2%;
    }
    .firstIcon {
        left: 4%;
    }
    .fourthIcon {
        right: 2%;
    }
}
@media screen and (max-width: 600px) {
    .title {
        font-size: 33px;
        line-height: 36px;
        max-width: 290px;
    }
    .thirdIcon {
        left: 1%;
        bottom: 10px;
    }
    .sixIcon {
        bottom: 100px;
        right: 0;
    }
    .firstIcon {
        left: 30%;
        top: 100px;
    }
    .fourthIcon {
        display: none;
    }
}
@media screen and (max-width: 360px) {
    .title {
        font-size: 26px;
        line-height: 36px;
        max-width: 240px;
    }
}
