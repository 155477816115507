/* + LEFT  */
.center {
  display: flex;
  align-items: center;
}
.header span {
  color: var(--main-color);
}
.label {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.51em;
  line-height: 18px;
  text-transform: uppercase;
}
.title {
  font-weight: 700;
  font-size: 45px;
  line-height: 65px;
  max-width: 500px;
  margin-block: 20px;
  position: relative;
}
.title::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -15px;
  height: 40px;
  width: 40px;
  background-image: url("../../../../Assets/icons/circle.png");
  background-size: contain;
}
.content {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: var(--main-gray);
  max-width: 530px;
  margin-bottom: 50px;
}

/* + RIGHT  */
.rightBox {
  display: flex;
}
.box {
  display: flex;
  justify-content: center;
  padding-block: 50px;
  flex-grow: 2;
}
.card {
  height: 320px;
  width: 250px;
  border-radius: 15px;
  position: relative;
  background: linear-gradient(
      0deg,
      rgba(207, 3, 3, 0.05),
      rgba(207, 3, 3, 0.05)
    ),
    url("../../../../Assets/images/heroHometwo.webp");
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 30px;
}
.card::after {
  content: "";
  position: absolute;
  top: -21px;
  right: 22px;
  height: 100%;
  width: 100%;
  border: 2px solid var(--main-color);
  border-radius: 15px;
}
.cardOneLight {
  position: absolute;
  top: -30px;
  right: -30px;
  height: 29px;
  width: 30px;
}
.cardTwoLight {
  position: absolute;
  bottom: -30px;
  left: -30px;
  height: 29px;
  width: 30px;
}
.secondCard {
  height: 320px;
  width: 250px;
  border-radius: 15px;
  position: relative;
  margin-top: 100px;
}
.card2 {
  height: 320px;
  width: 250px;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: linear-gradient(
      0deg,
      rgba(207, 3, 3, 0.05),
      rgba(207, 3, 3, 0.05)
    ),
    url("../../../../Assets/images/heroHomeOne.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.frame {
  height: 320px;
  width: 250px;
  border-radius: 15px;
  position: absolute;
  top: 21px;
  left: 22px;
  z-index: 1;
  border: 2px solid var(--main-color);
}
.social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
}
.line {
  flex-grow: 2;
  width: 2px;
  background-color: var(--main-color);
}
.social ul li {
  text-align: center;
  margin-block: 27px;
}
.social ul li:nth-child(2) {
  text-align: center;
  margin-block: 40px;
}
.social img {
  height: 25px;
}

/* + PARTNER */
.partnerSlide {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0px 4px 66px rgba(47, 44, 44, 0.05);
  border-radius: 5px;
  width: 100%;
  max-width: 1100px;
}
.logoPartner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 40px;
  height: 100%;
}
.logoPartner img {
  max-height: 40px;
}

@media screen and (max-width: 1199px) {
  .card,
  .secondCard,
  .card2,
  .frame {
    height: 280px;
    width: 200px;
  }
}
@media screen and (max-width: 991px) {
  .title {
    font-size: 25px;
    line-height: 35px;
  }

  .card,
  .secondCard,
  .card2,
  .frame {
    height: 320px;
    width: 250px;
  }
  .header {
    margin-inline: auto;
    text-align: center;
  }
  .rightBox {
    position: relative;
    padding-top: 100px;
  }
  .social {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: row;
    max-width: 500px;
    width: 100%;
  }
  .social ul {
    display: flex;
    align-items: center;
  }
  .social ul li {
    margin-inline: 30px;
  }
  .line {
    flex-grow: 2;
    height: 2px;
    background-color: var(--main-color);
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 610px) {
  .box {
    flex-direction: column;
    align-items: center;
  }
  .card {
    margin-right: 0;
  }
  .secondCard {
    margin-top: 25px;
  }
}
