.buttonTypeOne {
    background: var(--white);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
    border: 2px solid transparent;
    border-radius: 41px;
    color: var(--main-color);
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    padding: 16px 32px;
    transition: all var(--fast-transition);
    display: inline-block;
}

.buttonTypeOne:hover {
    background-color: var(--main-color);
    border: 2px solid var(--white);
    color: var(--white);
}

.buttonTypeTwo {
    background: var(--white);
    border: 2px solid var(--main-color);
    border-radius: 41px;
    color: var(--main-color);
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    padding: 16px 32px;
    transition: all var(--fast-transition);
    display: inline-block;
}

.buttonTypeTwo:hover {
    background-color: var(--main-color);
    color: var(--white);
}

.buttonTypeThree {
    background: var(--main-color);
    border: 2px solid var(--main-color);
    box-shadow: 0px 4px 25px rgba(207, 3, 3, 0.2);
    border-radius: 41px;
    color: var(--white);
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    padding: 20px 32px;
    transition: all var(--fast-transition);
    display: inline-block;
}

.buttonTypeThree:hover {
    border: 2px solid var(--main-color);
    background-color: var(--white);
    color: var(--main-color);
}
