.customRow {
  margin-bottom: 100px !important;
}

.image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  min-height: 250px;
}

.textContent {
  padding: 60px 20px;
}

.textContent h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 60px;
}

.textContent p {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--main-gray);
  margin-bottom: 20px;
}

.buttons a:nth-child(2) {
  margin-left: 20px;
}

@media screen and (max-width: 991px) {
  .customRow {
    margin-bottom: 30px !important;
  }

  .textContent {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .customRow {
    padding: 0 10px !important;
  }

  .textContent {
    padding: 20px 0px;
  }

  .textContent h3 {
    font-size: 18px;
    line-height: 20px;
  }

  .textContent p {
    font-size: 14px;
  }
}

@media screen and (max-width: 520px) {
  .buttons a:nth-child(2) {
    margin-left: 0px;
  }

  .buttons a div {
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
  }
}
