.statsContainer {
  background-image: url("../../../../Assets/images/stats_background_about.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 35px;
  display: flex;
}

.statsContainer .citation h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 70px;
  color: var(--white);
  font-style: italic;
  position: relative;
}

.statsContainer .yelloWord {
  color: var(--main-yellow);
  position: relative;
  z-index: 2;
}

.statsContainer h2 img {
  position: absolute;
  top: 0;
  left: -40px;
}

.statsContainer .quotationMarks {
  position: relative;
  display: inline-flex;
  z-index: 2;
}

.statsContainer .quotationMarks img {
  position: absolute;
  z-index: 1;
  left: 40px;
  top: 0px;
  transform: rotateY(180deg);
}

.sign {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  position: relative;
}

.sign h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 76px;
  margin-bottom: -50px !important;
  color: var(--white);
}

.sign img {
  max-height: 40px;
  position: absolute;
  bottom: -30px;
  right: -50px;
}

@media screen and (max-width: 991px) {
  .sign img {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .statsContainer {
    padding: 50px 10px;
  }

  .statsContainer .citation h2 {
    font-size: 24px;
    line-height: 42px;
    text-align: center;
  }

  .sign h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 525px) {
  .statsContainer {
    padding: 50px 0px;
  }

  .statsContainer h2 img {
    top: -10px;
    left: -10px;
    width: 40px;
  }

  .statsContainer .quotationMarks {
    position: relative;
    z-index: 2;
  }

  .statsContainer div {
    padding: 0;
  }
}
