.footerContainer {
  position: relative;
  background-color: #f6f6f6;
}

.footerContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../Assets/images/background_footer_texture.svg");
}

.footer {
  max-width: 1320px;
  margin-inline: auto;
  padding: 140px 20px 0;
  z-index: 1;
}

.footerLogo {
  max-height: 45px;
  margin-bottom: 23px;
}

.footer p {
  font-size: 14px;
  font-weight: 400;
  color: var(--dark-gray);
  margin-bottom: 10px;
  line-height: 35px;
  letter-spacing: -0.5px;
  max-width: 436px;
}

.centerColFooter {
  display: flex;
  justify-content: space-between;
}

.footer h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.5px;
  color: var(--main-black);
  margin-bottom: 20px;
}

.footer li a {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: var(--dark-gray);
}

.footer li:not(:last-child) {
  margin-bottom: 25px;
}

.socialColFooter {
  padding: 0 !important;
}

.footerIconsContainer {
  display: flex;
  gap: 40px;
}

.bottomFooter {
  width: 100%;
  padding: 30px 0;
  text-align: center;
}

.bottomFooter small {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: var(--dark-gray);
}

@media (max-width: 991px) {
  .centerColFooter {
    max-width: 436px;
  }

  .footer p {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .centerColFooter {
    margin-inline: auto;
  }

  .socialColFooter {
    padding: 10px !important;
    display: flex;
  }

  .socialColFooter span {
    margin-inline: auto;
  }
}
