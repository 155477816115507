.titleContainer {
  display: flex;
  align-items: center;
}

.titleContainer div {
  width: 100%;
}

.title {
  font-weight: 700;
  font-size: 36px;
  line-height: 86px;
  position: relative;
  margin: auto;
}

.title::after {
  content: "";
  position: absolute;
  width: 125px;
  height: 3px;
  background-color: var(--main-yellow);
  bottom: 38px;
  right: 30px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.card:nth-child(2) {
  margin-top: 30px;
}

.card:nth-child(3) {
  margin-top: -30px;
}

.card {
  padding: 32px;
  display: flex;
  align-items: center;
  background-color: var(--light-gray);
  max-height: 144px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.card::after {
  display: none;
  content: "";
  position: absolute;
  bottom: -20px;
  right: -50px;
  width: 100px;
  height: 100px;
  background-image: url("../../../../Assets/icons/circle.svg");
}

.card::before {
  display: none;
  content: "";
  position: absolute;
  bottom: -50px;
  right: 5px;
  width: 100px;
  height: 100px;
  background-image: url("../../../../Assets/icons/circle.svg");
}

.card:hover {
  background-color: var(--main-color);
  transition: 0.3s;
  color: white;
}

.card:hover::after,
.card:hover::before {
  display: block;
}

.card:hover .content p {
  color: white;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  background-color: var(--white);
  box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.iconContainer img {
  width: 40px;
  height: 40px;
}

.content {
  padding: 0px 20px;
}
.content h3 {
  font-weight: 700;
  font-size: 32px;
  margin: 0;
}

.content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: var(--dark-gray);
  margin: 0;
}

@media (max-width: 1320px) {
  .title::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .title {
    text-align: center;
  }

  .card:nth-child(2) {
    margin-top: unset;
  }

  .card:nth-child(3) {
    margin-top: unset;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 24px;
  }
  .cardContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}

@media (max-width: 430px) {
  .card {
    padding: 20px;
  }
  .content {
    padding: 10px 10px;
  }

  .content h3 {
    font-size: 18px;
  }

  .content p {
    font-size: 14px;
  }

  .iconContainer img {
    width: 32px;
    height: 32px;
  }
}
