/* + LEFT */
.leftBox {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 50px 0;
}
.box1 {
    height: 275px;
    width: 230px;
    border-radius: 20px 20px 0px 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url("../../../../Assets/images/noLimits1.webp");
    position: relative;
}
.box2 {
    height: 190px;
    width: 290px;
    border-radius: 20px 0px 20px 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url("../../../../Assets/images/noLimits2.webp");
    margin-bottom: 30px;
    position: relative;
}
.box3 {
    height: 206px;
    width: 380px;
    border-radius: 20px 20px 20px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url("../../../../Assets/images/noLimits3.webp");
    position: relative;
}

.box1 img {
    position: absolute;
    top: -10px;
    left: -35px;
    transform: scale(-1) rotate(110deg);
}
.box2 img {
    position: absolute;
    top: 5px;
    left: -30px;
    transform: scale(-1) rotate(80deg);
}
.box3 img {
    position: absolute;
    top: -10px;
    right: -35px;
}
/* + RIGHT */
.rightBox {
    display: flex;
    align-items: center;
}
.header h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 58px;
    color: var(--main-black);
    position: relative;
    margin-bottom: 24px;
}
.header h3::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 380px;
    transform: translateY(-50%);
    width: 110px;
    height: 4px;
    background-color: var(--main-yellow);
}
.header p {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--main-gray);
    margin-bottom: 22px;
}
@media screen and (max-width: 1199px) {
    .header {
        text-align: center;
        margin-inline: auto;
    }
    .header h3::after {
        display: none;
    }
    .header p {
        max-width: 650px;
    }
}
@media screen and (max-width: 767px) {
    .leftBox {
        flex-direction: column;
    }
    .box1 {
        margin-bottom: 30px;
        transform: translateX(-100px);
        z-index: 2;
    }
    .box1 {
        margin-bottom: 30px;
        transform: translateX(-100px);
        z-index: 2;
        width: 200px;
    }
    .box1 img {
        right: -35px;
        left: unset;
        transform: scale(-1) rotate(180deg);
    }
    .box2 img {
        bottom: -20px;
        top: unset;
        transform: scale(-1) rotate(0deg);
    }
    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -180px;
    }
    .box2 {
        transform: translateX(40px);
    }
    .box3 img {
        top: unset;
        bottom: -40px;
        right: 40px;
        transform: scale(-1) rotate(290deg);
    }
    .header h3 {
        font-size: 20px;
        line-height: 58px;
        margin-bottom: 0;
    }
    .header p {
        font-size: 16px;
        line-height: 28px;
    }
}
@media screen and (max-width: 420px) {
    .box3 {
        width: 310px;
    }
    .center {
        margin-top: 0;
    }
    .box1,
    .box2 {
        /* width: 250px; */
        transform: translateX(0);
    }
    .box2 {
        width: 260px;
    }
    .box2 img {
        left: -10px;
    }
    .box3 {
        width: 300px;
    }
}
