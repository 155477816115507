.callToAction {
  background-image: url("../../Assets/images/calltoaction_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 57px 70px;
  width: 1100px;
  max-width: 80%;
  margin-inline: auto;
  margin-bottom: -80px;
  position: relative;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 0px 4px 66px rgba(47, 44, 44, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.callToAction h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 70px;
  color: white;
  position: relative;
  max-width: 580px;
}

.styledH2::after {
  content: "";
  height: 4px;
  width: 125px;
  background-color: var(--main-yellow);
  border-radius: 10px;
  position: absolute;
  bottom: 30px;
  margin-left: 20px;
}

@media screen and (max-width: 1199px) {
  .callToAction h2 {
    max-width: 430px;
    font-size: 28px;
  }
}

@media screen and (max-width: 991px) {
  .callToAction h2 {
    max-width: 300px;
    font-size: 25px;
  }

  .styledH2::after {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .callToAction {
    flex-direction: column;
    padding: 37px 24px;
    max-width: 95%;
  }

  .callToAction h2 {
    max-width: 270px;
    line-height: 36px;
    font-size: 24px;
    margin-bottom: 22px;
    text-align: center;
  }

  .styledH2::after {
    display: none;
  }
}
