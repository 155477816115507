.sectionTitle {
  display: flex;
  align-items: center;
  max-width: 700px;
  margin-inline: auto;
  margin-bottom: 50px;
}

.sectionTitle h2 {
  padding-inline: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
}

.yellowLine {
  flex-grow: 2;
  height: 3px;
  background-color: var(--main-yellow);
}

.logoGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 0px 20px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  background-color: var(--light-gray);
}

.logoContainer:hover {
  background: var(--white);
  box-shadow: 30px 30px 80px rgba(0, 0, 0, 0.15);
  position:relative;
  z-index: 2;
  transition: var(--fast-transition);
}

@media screen and (max-width: 767px) {
  .logoGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .sectionTitle h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 575px) {
  .logoGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
