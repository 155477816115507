.aboutMeBox {
  padding: 72px 72px;
  background-color: transparent;
  background-image: url("../../Assets/images/about_me_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 45px;
  border-bottom-right-radius: 45px;
  position: relative;
}

.aboutMeBox::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 130px;
  background-image: url("../../Assets/images/ellipse_about_me.webp");
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  right: 0;
}

.aboutMeBox h3 {
  font-weight: 700;
  font-size: 37px;
  line-height: 60px;
  color: var(--white);
  margin-bottom: 12px;
  position: relative;
}

.aboutMeBox h3::after {
  content: "";
  height: 4px;
  width: 125px;
  background-color: var(--main-yellow);
  border-radius: 10px;
  position: absolute;
  top: 32px;
  margin-left: 20px;
}

.aboutMeBox p {
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: var(--white);
}

.aboutMeBadgeContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}

.aboutMeBadge {
  padding: 53px 28px;
  background-color: var(--white);
  border-radius: 10px;
  transition: var(--fast-transition);
}

.badgeHeader {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.badgeHeader h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.badgeHeader img {
  height: 32px;
  width: 32px;
  margin-right: 5px;
}

.aboutMeBadge p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-gray);
}

.aboutMeBadge:hover {
  box-shadow: 0px 4px 66px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 1120px) {
  .aboutMeBox h3::after {
    display: none;
  }

  .aboutMeBox p {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (max-width: 991px) {
  .aboutMeBadgeContainer {
    margin-top: 30px;
  }
  .aboutMeBadge {
    padding: 15px 20px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .badgeHeader {
    height: 70px;
    display: block;
    justify-content: center;
  }

  .badgeHeader img {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .aboutMeBadgeContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 32px;
  }
}

@media screen and (max-width: 520px) {
  .aboutMeBox {
    padding: 15px 26px;
  }

  .aboutMeBox p {
    font-size: 14px;
    line-height: 27px;
  }

  .aboutMeBoxContainer {
    padding: 0 !important;
  }

  .aboutMeBox::after {
    width: 80px;
    height: 80px;
  }
}
