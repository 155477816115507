.getInTouch {
  display: flex;
  margin-bottom: 21px;
}

.getInTouch h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
}

.yellowLine {
  height: 3px;
  width: 125px;
  background-color: var(--main-yellow);
  margin-top: 15px;
  margin-left: 10px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  margin-bottom: 60px;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.information h5 {
  font-weight: 600;
  font-size: 14px;
  color: var(--main-gray);
}

.information h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 16px;
  margin-bottom: 0;
}

.formMessage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formMessage h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  max-width: 360px;
}

.contactFormContainer {
  background: #ffffff;
  box-shadow: 0px 4px 66px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 32px;
  position: relative;
}

.contactFormContainer .header {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 36px;
  margin-inline: 12px;
}

.contactFormContainer .header h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  padding: 17px 0px;
}

.contactFormContainer .header span {
  color: var(--main-color);
}

.inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
}

.inputField input,
.inputField textarea {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 16px;
}

.inputField input:focus,
.inputField textarea:focus {
  outline: none;
}

.buttonContainer {
  text-align: right;
}

.error {
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
  max-width: 300px;
  color: var(--main-color);
  position: absolute;
  bottom: -25px;
}

@media screen and (max-width: 767px) {
  .contactFormContainer {
    padding: 10px;
  }

  .yellowLine {
    display: none;
  }

  .information h3 {
    font-size: 16px;
  }

  .content img {
    width: 35px;
  }
}
