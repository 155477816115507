.hero {
  background-image: url("../../Assets/images/bgAbout.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-block: 130px 90px;
  margin-bottom: 150px;
  overflow: hidden;
}
.aboutMe,
.myCustomers,
.noLimits,
.stats,
.pathTogether {
  margin-bottom: 150px;
}
.noLimits {
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .hero,
  .myCustomers,
  .noLimits,
  .stats,
  .pathTogether {
    margin-bottom: 60px;
  }

  .aboutMe {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 485px) {
  .hero {
    padding-block: 100px 90px;
  }
}
