.hero {
  padding-block: 130px;
  background: linear-gradient(to left, #f7f7f7, #f7f7f7 81%, #ffffff 19%);
  position: relative;
  margin-bottom: 150px;
}

.aboutMe,
.myBusiness {
  margin-bottom: 150px;
}
.review {
  overflow: hidden;
  margin-bottom: 150px;
}

@media screen and (max-width: 1199px) {
  .hero {
    background: linear-gradient(to left, #f7f7f7, #f7f7f7 70%, #ffffff 30%);
  }
}

@media screen and (max-width: 991px) {
  .hero,
  .myBusiness,
  .review {
    margin-bottom: 100px;
  }

  .aboutMe {
    margin-bottom: 50px;
  }
}
