.hero {
  background-image: url("../../Assets/images/heroContact.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  margin-bottom: 80px;
  overflow: hidden;
}

.contactForm {
  margin-bottom: 150px;
}
