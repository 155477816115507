.sectionTitle {
  display: flex;
  align-items: center;
  max-width: 700px;
  margin-inline: auto;
  margin-bottom: 50px;
}

.sectionTitle h2 {
  padding-inline: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
}

.yellowLine {
  flex-grow: 2;
  height: 3px;
  background-color: var(--main-yellow);
}

.logoGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  padding: 0px 20px;
}

.logoGrid .card {
  text-align: center;
}

.logoGrid .card img {
  width: 56px;
  height: 56px;
  margin-bottom: 30px;
}

.logoGrid .card h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  height: 60px;
}

.logoGrid .card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--dark-gray);
}

@media screen and (max-width: 991px) {
  .logoGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .logoGrid .card h3 {
    height: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sectionTitle h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 575px) {
  .logoGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .logoGrid .card h3 {
    height: unset;
  }
}
