.headerContainer {
  margin-inline: auto;
  margin-bottom: 80px;
}

.headerContainer p {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: var(--main-gray);
  text-align: center;
  max-width: 700px;
  margin-inline: auto;
  padding: 15px 10px;
}

.header {
  max-width: 605px;
  margin-inline: auto;
}

.sectionTitle {
  display: flex;
  align-items: center;
}

.sectionTitle h2,
.pathTogetherContainer h2 {
  padding-inline: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
}

.yellowLine {
  flex-grow: 2;
  height: 3px;
  margin: 0 15px;
  background-color: var(--main-yellow);
}

.content {
  display: flex;
  justify-content: space-around;
}

.card {
  max-width: 250px;
}

.iconContainer {
  display: inline-flex;
  width: 75px;
  height: 75px;
  padding: 14px;
  border-radius: 8px;
  background: rgba(43, 121, 194, 0.08);
  box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.06);
  margin-bottom: 24px;
}

.content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
}

.content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: var(--dark-gray);
}

@media screen and (max-width: 991px) {
  .content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .card {
    max-width: 300px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sectionTitle h2,
  .pathTogetherContainer h2 {
    font-size: 24px;
  }

  .header {
    max-width: 420px;
    margin-inline: auto;
  }

  .headerContainer p {
    font-size: 16px;
  }
}

@media screen and (max-width: 420px) {
  .sectionTitle {
    display: none;
  }

  .pathTogetherContainer h2 {
    padding: 0 5px;
  }

  .pathTogetherContainer h2::after {
    content: " a path together";
  }
}
