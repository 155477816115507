.header {
    color: var(--white);
    height: 100%;
}

.content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 1050px;
    margin-inline: auto;
    padding-inline: 70px;
}
.label {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.51em;
    color: var(--main-yellow);
    text-transform: uppercase;
}
.content h1 {
    font-weight: 700;
    font-size: 40px;
    margin-block: 21px;
}
.content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
}

.social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
}
.line {
    flex-grow: 2;
    width: 2px;
    background-color: var(--white);
}
.social ul li {
    text-align: center;
    margin-block: 27px;
}
.social ul li:nth-child(2) {
    text-align: center;
    margin-block: 40px;
}
.social img {
    height: 25px;
}

@media screen and (max-width: 767px) {
    .social {
        display: none;
    }
    .content {
        padding-inline: 0px;
    }
    .content h1 {
        font-size: 25px;
    }
    .content p {
        font-size: 16px;
        line-height: 26px;
    }
    .label {
        font-size: 12px;
        line-height: 12px;
    }
}
