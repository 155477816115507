.statsContainer {
  background-image: url("../../../../Assets/images/stats_background_about.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 80px 80px;
}

.stats h2 {
  font-weight: 700;
  font-size: 48px;
  color: var(--white);
}

.stats p {
  font-weight: 500;
  font-size: 18px;
  color: var(--white);
}

@media screen and (max-width: 991px) {
  .statsContainer {
    grid-template-columns: repeat(2, 1fr);
    padding: 60px 40px;
  }

  .stats{
    text-align: center;
  }
}

@media screen and (max-width: 525px) {
    .statsContainer {
      grid-template-columns: repeat(1, 1fr);
      padding: 40px 40px;
    }

    .stats h2 {
        font-size: 24px;
      }
      
      .stats p {
        font-size: 16px;
      }
  }
