/* + LEFT */
.leftBox {
    display: flex;
    position: relative;
}
.social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
}
.line {
    flex-grow: 2;
    width: 2px;
    background-color: var(--main-color);
}
.social ul li {
    text-align: center;
    margin-block: 27px;
}
.social ul li:nth-child(2) {
    text-align: center;
    margin-block: 40px;
}
.social img {
    height: 25px;
}
.header {
    width: 100%;
}
.header .evidence {
    color: var(--main-color);
    position: relative;
}
.closeText {
    position: relative;
    display: inline-block;
}
.apexOpened::before {
    content: "";
    position: absolute;
    z-index: 0;
    top: -15px;
    left: -40px;
    width: 58px;
    height: 38px;
    background: url("../../../../Assets/icons/apexOpened.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}
.apenClosed::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0px;
    right: -30px;
    width: 58px;
    height: 38px;
    background: url("../../../../Assets/icons/apexClosed.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}
.text {
    position: relative;
    z-index: 2;
}
.title {
    max-width: 620px;
    margin-left: auto;
    margin-block: 80px;
    margin-inline: auto;
}
.title h1 {
    font-weight: 700;
    font-size: 39px;
    line-height: 68px;
    margin-bottom: 50px;
}
.title .subtitle {
    margin-top: 70px;
}
.title .subtitle img {
    max-height: 30px;
}
/* + RIGHT */
.rightBox {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}
.ellipseImage {
    width: 360px;
    height: 540px;
    border-radius: 500px;
    background-image: url("../../../../Assets/images/heroAbout.webp");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
}
.ellipseImageBorder {
    width: 360px;
    height: 540px;
    position: absolute;
    top: 0;
    left: 200px;
    border: 2px solid var(--main-color);
    border-radius: 500px;
    z-index: 1;
}

@media screen and (max-width: 1199px) {
    .title {
        margin-inline: auto;
        max-width: 550px;
    }
    .title h1 {
        font-size: 32px;
    }
    .rightBox {
        justify-content: center;
    }
    .ellipseImage,
    .ellipseImageBorder {
        width: 280px;
        height: 400px;
    }
    .ellipseImageBorder {
        top: 50px;
    }
}
@media screen and (max-width: 991px) {
    .header {
        margin-bottom: 60px;
    }
    .title {
        text-align: center;
    }
    .title h1 {
        line-height: 54px;
    }
    .title .subtitle {
        margin-top: 50px;
    }
    .social {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        flex-direction: row;
        max-width: 500px;
        width: 100%;
    }
    .social ul {
        display: flex;
        align-items: center;
    }
    .social ul li {
        margin-inline: 30px;
    }
    .line {
        flex-grow: 2;
        height: 2px;
        background-color: var(--main-color);
        margin-bottom: 10px;
    }
    .rightBox {
        display: inline-block;
    }
    .ellipseImageBorder {
        left: 35px;
        top: 0;
    }
    .responsive {
        text-align: center;
    }
}
@media screen and (max-width: 767px) {
    .apenClosed::after {
        right: -38px;
    }
}
@media screen and (max-width: 485px) {
    .title h1 {
        font-size: 30px;
        line-height: 35px;
    }
    .title .subtitle img {
        display: none;
    }
    .apenClosed::after,
    .apexOpened::before {
        width: 41px;
        height: 28px;
    }
    .apexOpened::before {
        top: -10px;
        left: -30px;
    }
    .apenClosed::after {
        right: -25px;
    }
    .ellipseImage {
        margin-left: -10px;
    }
    .ellipseImageBorder {
        left: 155px;
        top: 0;
    }
}
@media screen and (max-width: 349px) {
    .title h1 {
        font-size: 25px;
        line-height: 30px;
    }
}
