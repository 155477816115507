.hero {
  background-image: url("../../Assets/images/heroServices.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
  border-bottom-left-radius: 250px;
  margin-bottom: 150px;
  overflow: hidden;
  height: 750px;
}

.myCustomers {
  margin-bottom: 150px;
}

.citation {
  margin-bottom: 150px;
}

.whatCanTogether {
  margin-bottom: 150px;
}

@media screen and (max-width: 991px) {
  .hero,
  .myCustomers,
  .citation,
  .whatCanTogether {
    margin-bottom: 80px;
  }

  .aboutMe {
    margin-bottom: 50px;
  }
}
