.leftBox {
    background-color: #f7f7f7;
    padding-block: 70px;
    padding-inline: 50px;
    border-radius: 0px 65px 65px 0px;
}
.header .title {
    position: relative;
    display: inline-flex;
}
.header .title h2 {
    font-weight: 700;
    font-size: 36px;
    color: var(--main-black);
}
.header .title h2::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -140px;
    background-color: var(--main-yellow);
    width: 120px;
    height: 3px;
}
.header p {
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: var(--main-gray);
    margin-top: 24px;
}
.header .title img {
    position: absolute;
    top: -25px;
    left: -30px;
}
.box {
    margin-top: 80px;
}
.box .avatar {
    width: 135px;
    height: 135px;
    border-radius: 50%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
}
.box .avatar .background {
    width: 115px;
    height: 115px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.box p {
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    color: var(--main-black);
    text-align: center;
    max-width: 580px;
    margin-inline: auto;
    margin-top: 30px;
    margin-bottom: 80px;
}
/* + RIGHT */
.rightBox {
    height: 100%;
    position: relative;
    margin-left: 50px;
}
.circle {
    height: 590px;
    width: 590px;
    border-radius: 50%;
    border: 3px solid #cf030352;
    position: absolute;
    top: 90px;
    animation: rotateall 35s linear infinite;
}
.centerImage {
    position: absolute;
    height: 300px;
    width: 300px;
    margin-left: 142px;
    margin-top: 142px;
    animation: rotateallBack 35s linear infinite;
}
.miniCircle1 {
    background-color: var(--main-color);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    top: 73%;
    left: 29px;
}
.miniCircle2 {
    background-color: var(--main-color);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 60%;
}
.miniCircle3 {
    background-color: var(--main-color);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: 15px;
    right: 30%;
}
.image1,
.image2,
.image3,
.image4,
.image5,
.image6 {
    background-color: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    animation: rotateallBack 35s linear infinite;
}

.image1 {
    height: 54px;
    width: 54px;
    top: 23px;
    left: 100px;
}
.image2 {
    height: 78px;
    width: 78px;
    top: 33%;
    left: -32px;
}
.image3 {
    height: 54px;
    width: 54px;
    bottom: 5%;
    left: 90px;
}
.image4 {
    height: 44px;
    width: 44px;
    bottom: -20px;
    left: 50%;
}
.image5 {
    height: 44px;
    width: 44px;
    top: 20%;
    right: 20px;
}
.image6 {
    height: 78px;
    width: 78px;
    top: 70%;
    right: 10px;
}
.background {
    height: 84.6%;
    width: 84.6%;
    border-radius: 50%;
    background-color: var(--white);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@keyframes rotateall {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotateallBack {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@media screen and (max-width: 1199px) {
    .rightBox {
        display: none;
    }
}
@media screen and (max-width: 767px) {
    .header {
        text-align: center;
    }
    .header .title h2 {
        font-size: 26px;
        line-height: 23px;
    }
    .header .title h2::after {
        display: none;
    }
    .header p {
        font-size: 14px;
        line-height: 24px;
    }
    .box p {
        font-size: 16px;
        line-height: 24px;
    }
}
