@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Root Variables */
:root {
    /* Sizes */
    --nav-bar-height: 130px;
    /* Colors */
    --white: #ffffff;
    --main-color: #cf0303;
    --main-gray: #9d9d9d;
    --light-gray: #f6f6f6;
    --dark-gray: #3c3c3ccc;
    --main-black: #151e2c;
    --main-yellow: #fed70a;
    /* Transition */
    --fast-transition: 0.3s;
    --medium-transition: 0.6s;
    --slow-transition: 0.9s;
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Import Font */
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
a,
span,
li,
ul,
ol,
button,
input,
textarea {
    font-family: "Montserrat", sans-serif;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

ul {
    list-style: none;
    padding: 0;
}

li a,
div a {
    text-decoration: none;
}

a:hover {
    color: var(--main-color);
}

section div.row {
    padding: 0 40px;
    margin: 0;
    width: 100%;
}
.swiper-pagination-bullet {
    background-color: var(--main-color);
    opacity: 1;
    height: 7px;
    width: 7px;
    border-radius: 5px;
}
.swiper-pagination-bullet-active {
    background-color: var(--main-color);
    width: 34px;
    height: 7px;
    border-radius: 3px;
}

@media screen and (max-width: 991px) {
    section div.row {
        padding: 0 20px;
    }
}
