.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--nav-bar-height);
  position: absolute;
  width: 100%;
  padding: 0 60px;
  z-index: 99;
}

.navBarLogo img{
  max-height: 45px;
}

.navBarMenu {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  margin-top: 5px;
  padding: 0;
}

.navBarMenuItem,
.navBarMenuItemActive {
  margin: 0 22px;
  position: relative;
}

.navBarMenuItem::after {
  content: "";
  height: 2px;
  position: absolute;
  bottom: -4px;
  left: 0;
  background-color: var(--main-color);
  border-radius: 10px;
  width: 0;
  transition: all var(--fast-transition);
}

.navBarMenuItem:hover::after {
  width: 100%;
}

.navBarMenuItemActive::after {
  content: "";
  height: 2px;
  position: absolute;
  bottom: -4px;
  left: 0;
  background-color: var(--main-color);
  border-radius: 10px;
  width: 100%;
  transition: all var(--fast-transition);
}

.navBarMenuLink {
  color: var(--main-gray);
  font-weight: 600;
  font-size: 16px;
}

.navBarMenuLinkWhite {
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
}

.navBarMenuLinkActive {
  color: var(--main-color);
}

.navBarIcon {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.navBarIconDesktop {
  margin-top: 5px;
}

.navBarIconMobile {
  display: none;
}

.iconPartOne {
  width: 30px;
  height: 3px;
  border-radius: 10px;
  background-color: var(--main-black);
  margin-bottom: 7px;
  transition: all var(--fast-transition);
}

.navBarIconMobile.active .iconPartOne {
  width: 30px;
  transform: translateY(10px) rotate(-45deg);
}

.iconPartTwo {
  width: 22px;
  height: 3px;
  border-radius: 10px;
  background-color: var(--main-black);
  opacity: 1;
  transition: all var(--fast-transition);
}

.navBarIconMobile.active .iconPartTwo {
  opacity: 0;
}

.iconPartThree {
  width: 14px;
  height: 3px;
  border-radius: 10px;
  background-color: var(--main-black);
  margin-top: 7px;
  transition: all var(--fast-transition);
}

.navBarIconMobile.active .iconPartThree {
  width: 30px;
  transform: translateY(-10px) rotate(45deg);
}

.mobileMenu {
  display: none;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: all var(--fast-transition);
  transform: translateY(-100%);
  background-image: url("../../Assets/images/menu_background.webp");
}

.mobileMenu nav {
  margin-top: 20px;
  height: calc(100% - 95px);
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.menuIconsContainer {
  text-align: center;
  flex-grow: 1;
  margin-bottom: 41px;
}

.menuIconsContainer .menuIcon {
  height: 30px;
  margin: 0 20px;
}

.mobileMenuOpen {
  transform: translateY(0);
}

.bodyScrollDisabled {
  overflow: unset;
}

@media screen and (max-width: 767px) {
  .navBar {
    padding: 0 20px;
  }

  .bodyScrollDisabled {
    overflow: hidden;
  }

  .navBarMenu,
  .navBarIconDesktop {
    display: none;
  }

  .navBarIconMobile {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
    width: 30px;
    text-align: right;
    cursor: pointer;
    z-index: 110;
  }

  .navBarLogo {
    position: relative;
    z-index: 110;
    max-height: 35px;
  }

  .navBarLogo img{
    max-height: 35px;
  }

  .navBarMenuItem,
  .navBarMenuItemActive {
    font-weight: 600;
    font-size: 18px;
    margin: 35px 0;
    position: relative;
  }

  .navBarMenuItem a,
  .navBarMenuItemActive a {
    font-weight: 600;
    font-size: 24px;
  }

  .mobileMenu {
    display: block;
  }
}
