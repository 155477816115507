.sectionTitle {
  display: flex;
  align-items: center;
  max-width: 700px;
  margin-inline: auto;
  margin-bottom: 50px;
}

.sectionTitle h2 {
  padding-inline: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
}

.yellowLine {
  flex-grow: 2;
  height: 3px;
  background-color: var(--main-yellow);
}

@media screen and (max-width: 767px) {
  .sectionTitle h2 {
    font-size: 24px;
  }
}
